import { fetchAuthSession } from "aws-amplify/auth";

import React, { useEffect, useState } from "react";


import { Navigate } from "react-router-dom";
// import { UserContext } from "../utils/context/UserContext";

const ProtectedRoutes = ({ children }) => {
    const [userState02, setUserState02] =  useState(false) //useContext(UserContext)
    
    const [loading, setLoading] = useState(true)
   
    const findAuthSession = async () => {
        const user = await fetchAuthSession().then(token => {
            return token;
        });
        return user
    }
    useEffect(() => {
        if (!userState02) {
            findAuthSession().then(res => {
                setUserState02(res)
                setLoading(false)
                return res
            })  
        }
    });

    if ((userState02 === undefined && loading === false) || (userState02?.tokens?.signInDetails?.loginId === undefined && loading === false)) return (

        <Navigate to="/login" />
    );
    else if ((userState02 !== undefined && loading === false) || (userState02?.tokens?.signInDetails?.loginId !== undefined && loading === false)) return (children);
};

export default ProtectedRoutes;