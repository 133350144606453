import { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { CognitoUser } from 'amazon-cognito-identity-js';
import { signIn, confirmSignIn } from "aws-amplify/auth"
import { fetchAuthSession } from 'aws-amplify/auth';
import { UserContext } from '../utils/context/UserContext';
import { Icon } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';


export default function LoginView() {


    const { userState02, setUserState02 } = useContext(UserContext)

    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate()
    const [value, setValue] = useState('1');
    const [formValues, setFormValues] = useState({})

    let cognitoUser = CognitoUser;
    const [otpSent, setOtpSent] = useState(false)
    useEffect(() => {
        if (userState02?.tokens?.signInDetails?.loginId !== undefined) {
            navigate('/dashboard')
        }
    })

    const handleLoginClick = async () => {
        try {
            // await Auth.signIn(username, password)
            // cognitoUser = await signIn("irfan.nazer@qualigytech.com");
            
            cognitoUser = await signIn({
                username: formValues?.email, //"captainmiller842@gmail.com",
                options: {
                    authFlowType: 'CUSTOM_WITHOUT_SRP',
                },
            });
            if (!cognitoUser.isSignedIn && cognitoUser?.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
                setOtpSent(true)
            }



        } catch (err) {
            console.log('error signing up..', err);

        }
    }

    const handleOtpSubmit = async () => {
        // cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoData, otpvalue);
        try {
            const challengeResponse = formValues?.password;
            cognitoUser = await confirmSignIn({ challengeResponse });
            
            if (cognitoUser.isSignedIn && cognitoUser.nextStep.signInStep === "DONE") {
                setUserState02(null);
                navigate('/dashboard')
            }
        } catch (error) {
            console.log("fetchData error", error)
            
        }

        try {

            const sessionData = await fetchAuthSession();
            if (sessionData) {
                // setCurrentSession(sessionData)
            }
            // const {
            //     idToken,
            //     accessToken
            // } = tokens;
            
            // await Auth.currentSession();
            // await fetchAuthSession();
        } catch {
            // console.log('Apparently the user did not enter the right code');
        }

    }


    const handleformChange = (e) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value })
    }
    // const CustomSignUp = async (email, fullName) => { // CustomSignUp("irfan.nazer@qualigytech.com", "Irfan")
    //     const params = {
    //         username: email,
    //         password: "Tswl@123", // getRandomString(30),   "irjI892@"
    //         options: {
    //             userAttributes: {
    //                 name: fullName,
    //                 "custom:Roles": "Volunteer"
    //             }
    //         }
    //     };
    //     const signUpInfo = await signUp(params);
    //     // const { isSignUpComplete, userId, nextStep } = signUpInfo;
    //     console.log("signUpInfo", signUpInfo);
    // }

    // function getRandomString(bytes) {
    //     const randomValues = new Uint8Array(bytes);
    //     window.crypto.getRandomValues(randomValues);
    //     return Array.from(randomValues).map(intToHex).join('');
    // }

    // function intToHex(nr) {
    //     return nr.toString(16).padStart(2, '0');
    // }


    const renderForm = (
        <div style={{ marginTop: '2rem' }}>
            <Stack spacing={3}>
                {
                    !otpSent &&
                    <TextField
                        name="email"
                        label="Email"
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><Icon>email</Icon></InputAdornment>,
                        }}
                        onChange={(e) => handleformChange(e)}
                    />}

                {
                    otpSent &&
                    <TextField
                        name="password"
                        label="One time password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => handleformChange(e)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        {showPassword ? <VisibilityIcon></VisibilityIcon> : <VisibilityOffIcon></VisibilityOffIcon>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />}
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3 }}>

                {/* <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    id='vol'
                    onClick={(e) => handleClick(e)}
                    
                    sx={{
                        ':hover': {
                            bgcolor: '#2f2f31', // theme.palette.primary.main
                            color: 'white',
                        },
                        backgroundColor: '#18181b'
                    }}
                    value="2"
                >
                    Skip
                </LoadingButton> */}
                {
                    otpSent &&
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        // sx={{backgroundColor:'#18181b'}}
                        // onClick={handleClick}
                        sx={{
                            ':hover': {
                                bgcolor: '#2f2f31', // theme.palette.primary.main
                                color: 'white',
                            },
                            backgroundColor: '#18181b'
                        }}
                        value="2"
                        // onClick={(e) => handleTabChange(e)}

                        onClick={() => (handleOtpSubmit())}
                    >
                        Verify OTP
                    </LoadingButton>}
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 3 }}>
                {/* <Link variant="subtitle2" underline="hover">
                    Forgot password?
                </Link> */}

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    // sx={{backgroundColor:'#18181b'}}
                    // onClick={handleClick}
                    sx={{
                        ':hover': {
                            bgcolor: '#2f2f31', // theme.palette.primary.main
                            color: 'white',
                        },
                        backgroundColor: '#18181b'
                    }}
                    value="2"
                    onClick={() => (handleLoginClick())}
                >
                    {`${otpSent ? "Resend OTP" : "Login"}`}
                </LoadingButton>
            </Stack>





        </div>
    );
    const renderForm2 = (
        <div style={{ display: 'flex', flexDirection: 'column', height: '25vh', justifyContent: 'center', }}>
            <Typography variant="h6" sx={{ mt: 2, mb: 7 }}>
                Choose your Role
            </Typography>
            <Stack direction="row" spacing={2}>
                <Button
                    fullWidth
                    size="large"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderColor: "#78787d" }}
                    id='vol'
                // onClick={(e) => handleClick(e)}
                >
                    Volunteer
                </Button>

                <Button
                    fullWidth
                    size="large"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderColor: "#78787d" }}
                    id='stu'
                // onClick={(e) => handleClick(e)}
                >
                    Student
                </Button>

                {/* <Button
                    fullWidth
                    size="large"
                    color="inherit"
                    variant="outlined"
                    sx={{ borderColor: "#f8f4f4" }}
                >
                   
                </Button> */}
            </Stack>
        </div>
    );

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: "100%", minHeight: '100vh', backgroundColor: '#f4f6f6', justifyContent: 'center' }}>
            <div style={{ width: '100%', maxWidth: '70vw', backgroundColor: '#f4f6f6' }}>
                {/* <Box
                    sx={{
                        // ...bgGradient({
                        //     color: alpha(theme.palette.background.default, 0.9),
                        //     imgUrl: '/assets/background/overlay_4.jpg',
                        // }),
                        height: 1,
                        backgroundColor: 'yellowgreen'
                    }}
                > */}
                {/* <Logo
                sx={{
                    position: 'fixed',
                    top: { xs: 16, md: 24 },
                    left: { xs: 16, md: 24 },
                }}
            /> */}

                <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
                    <Card
                        sx={{
                            p: 5,
                            width: '100%',
                            maxWidth: 500,
                            height: '100%',
                            maxHeight: 700,
                            minHeight: 480,
                        }}
                    >
                        <Typography variant="h4">Login</Typography>

                        {value === '1' &&
                            <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                                or
                                <Typography variant="title" noWrap>
                                    &nbsp;
                                </Typography>
                                {/* <Link variant="subtitle2" to={"/signup"} sx={{ ml: 0.5 }}>
                                    create an account
                                </Link> */}
                            </Typography>

                        }
                        {value === '2' &&
                            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                                Choose your Role
                            </Typography>}

                        {/* <Stack direction="row" spacing={2}>
                                <Button
                                    fullWidth
                                    size="large"
                                    color="inherit"
                                    variant="outlined"
                                    sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
                                >
                                    <Iconify icon="eva:google-fill" color="#DF3E30" />
                                </Button>

                                <Button
                                    fullWidth
                                    size="large"
                                    color="inherit"
                                    variant="outlined"
                                    sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
                                >
                                    <Iconify icon="eva:facebook-fill" color="#1877F2" />
                                </Button>

                                <Button
                                    fullWidth
                                    size="large"
                                    color="inherit"
                                    variant="outlined"
                                    sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
                                >
                                    <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
                                </Button>
                            </Stack>

                            <Divider sx={{ my: 3 }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    OR
                                </Typography>
                            </Divider> */}

                        {/* {renderForm} */}
                        <TabContext value={value}>
                            <TabPanel value="1">{renderForm}
                                {/* <Button value="2" >to item 2</Button> */}
                            </TabPanel>
                            <TabPanel value="2">
                                {renderForm2}
                            </TabPanel>
                        </TabContext>
                    </Card>
                </Stack>
                {/* </Box> */}
            </div>
        </div>
    );
}
