import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack'
import amplifyconfig from './amplifyconfiguration-two.json';
// Amplify.configure(amplifyconfig);


// Amplify.configure({
//   Auth: {
//     region: 'ap-south-1',
//     userPoolId: "ap-south-1_6TApbxBWn",
//     identityPoolId: "ap-south-1:c2f3a0ab-dce2-4e6f-86f0-44df456fb68f",
//     userPoolWebClientId: "28vh3ro7bo704pekop0281suan",
//     "oauth": {
//       "domain": "https://toservewithlove.auth.ap-south-1.amazoncognito.com",
//       "scope": [
//         "phone",
//         "email",
//         "openid",
//         "profile",
//         "aws.cognito.signin.user.admin"
//       ],
//       "redirectSignIn": "https://dev.toservewithlove.in/",
//       "redirectSignOut": "https://dev.toservewithlove.in/",
//       "responseType": "code"
//     },
//     "federationTarget": "COGNITO_USER_POOLS"
//   }, API: {
//     endpoints: [
//       {
//         "name": "aws-node-rest-api-13thJan",
//         "endpoint": "https://b1hkxqxd09.execute-api.ap-south-1.amazonaws.com/dev",
//         "region": "ap-south-1"
//       }
//     ]
//   }
// });


Amplify.configure(amplifyconfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
