import { Route, Routes } from 'react-router-dom';
import './App.css';
import LoginView from './pages/login';
import ProtectedRoutes from './routes/ProtectedRoutes';
import { lazy } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SignUpView from './pages/Signup';
import Loadable from './components/Loadable';
import {  UserProvider } from './utils/context/UserContext';
import MessageDialog from './components/common/MessageDialog';
import ImageViewer from './components/common/ImageViewer';

const Home = Loadable(lazy(() => import('./pages/Home/Home')));
const Dashboard = Loadable(lazy(() => import('./pages/Dashboard')));
const DashboardDefault = Loadable(lazy(() => import('./pages/DashboardDefault')));
const RoleMgmt = Loadable(lazy(() => import('./pages/RoleMgmt/RoleMgmt')));
const UserMgmt = Loadable(lazy(() => import('./pages/UserMgmt/UserMgmt')));
const AddUser = Loadable(lazy(() => import('./pages/UserMgmt/AddUser')));
const UserProfile = Loadable(lazy(() => import('./pages/UserProfile')));
const ApplyScholarship = Loadable(lazy(() => import('./pages/Scholarship/ApplyScholarship')));
const Messages = Loadable(lazy(() => import('./pages/Messages')));
const ViewScholarship = Loadable(lazy(() => import('./pages/Scholarship/ViewScholarship')));
const PendingTasks = Loadable(lazy(() => import('./pages/Tasks/Pending/PendingTasks')));
const RequestPayment = Loadable(lazy(() => import('./pages/Payments/RequestPayment/RequestPaymentMain')));
const PaymentProcess = Loadable(lazy(() => import('./pages/Payments/PaymentProcess')));
const SubmitQuestion = Loadable(lazy(() => import('./pages/QAMgmt/SubmitQuestion')));
const AddQuestion = Loadable(lazy(() => import('./pages/QAMgmt/AddQuestion/AddQuestion')));
const ModifyQuestion = Loadable(lazy(() => import('./pages/QAMgmt/ModifyQuestion/ModifyQuestion')));
function App() {


 

  return (
    <div className="App">

      
      <UserProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MessageDialog/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/dashboard' element={<ProtectedRoutes ><Dashboard /></ProtectedRoutes>}>
              <Route path='/dashboard' element={<ProtectedRoutes ><DashboardDefault /></ProtectedRoutes>}></Route>
              <Route path='role-mgmt' element={<ProtectedRoutes ><RoleMgmt /></ProtectedRoutes>}></Route>
              <Route path='user-mgmt' element={<ProtectedRoutes ><UserMgmt /></ProtectedRoutes>}></Route>
              <Route path='add-user' element={<ProtectedRoutes ><AddUser /></ProtectedRoutes>}></Route>
              <Route path='user-profile' element={<ProtectedRoutes ><UserProfile /></ProtectedRoutes>}></Route>
              <Route path='apply-scholarship' element={<ProtectedRoutes ><ApplyScholarship /></ProtectedRoutes>}></Route>
              <Route path='vol-opp' element={<ProtectedRoutes ><Messages /></ProtectedRoutes>}></Route>
              <Route path='view-application' element={<ProtectedRoutes ><ViewScholarship /></ProtectedRoutes>}></Route>
              <Route path='pending-voltasks' element={<ProtectedRoutes ><PendingTasks /></ProtectedRoutes>}></Route>
              <Route path='request-payment' element={<ProtectedRoutes ><RequestPayment /></ProtectedRoutes>}></Route>
              <Route path='pending-payment' element={<ProtectedRoutes ><PaymentProcess /></ProtectedRoutes>}></Route>
              <Route path='image-viewer' element={<ProtectedRoutes ><ImageViewer /></ProtectedRoutes>}></Route>
              <Route path='question-submit' element={<ProtectedRoutes ><SubmitQuestion /></ProtectedRoutes>}></Route>
              <Route path='add-question' element={<ProtectedRoutes ><AddQuestion /></ProtectedRoutes>}></Route>
              <Route path='modify-question' element={<ProtectedRoutes ><ModifyQuestion /></ProtectedRoutes>}></Route>
            </Route>
            <Route path="/login" element={<LoginView />} />
            {/* <Route path="/signup" element={<SignUpView />} /> */}
          </Routes>
        </LocalizationProvider>
      </UserProvider>
    </div>
  );
}

export default App;
