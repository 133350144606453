// src/components/MessageDialog.js
import React, { useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { UserContext } from '../../utils/context/UserContext';

const MessageDialog = ({ }) => {

    const { openMsg, setOpenMsg } = useContext(UserContext)

    const handleMsgClose = () => {
        setOpenMsg({})
    }
    return (
        <Dialog open={Object.keys(openMsg)?.length !== 0} onClose={handleMsgClose}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "auto",
                        height: "auto",
                        minWidth:'30vw',
                        minHeight:'25vh',
                        maxHeight: "40vh",
                        maxWidth: "60vw",  // Set your width here
                    },
                },
            }}
        >
            <DialogTitle sx={{fontWeight:600, fontSize:'1.8rem', backgroundColor:''}} >{openMsg?.title}</DialogTitle>
            <DialogContent sx={{backgroundColor:'', padding:'1.8rem'}}>
                <DialogContentText sx={{fontSize:'18px'}} >{openMsg?.content}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:'',padding:'1rem' }} >
                <Button onClick={handleMsgClose} variant="contained">
                    Close
                </Button>
                {/* <Button onClick={onConfirm} variant="contained" color='primary' >
          Delete
        </Button> */}
            </DialogActions>
        </Dialog>
    );
};

export default MessageDialog;
