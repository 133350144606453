
// import React from 'react';
// export  const UserContext = React.createContext();

// src/contexts/UserContext.js
import { fetchAuthSession } from 'aws-amplify/auth';
import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

// export const useData = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [userState02, setUserState02] = useState(null)
    const [permissions, setPermissions] = useState({})
    const [loading, setLoading] = useState(false)
    const [roles, setRoles] = useState([]);
    const [openMsg, setOpenMsg] = useState({})
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxImages, setLightboxImages] = useState([])

    const dosomething = async () => {

        const user = await fetchAuthSession().then(token => {
            return token;
        });

        return user
    }



    useEffect(() => {
        const fetchUserState = () => {
            dosomething().then(res => {

                setUserState02(res)
                return res
            })
            setLoading(false)
        }
        if (userState02 === null) {
            setLoading(true)
            fetchUserState()
        }
    }, [userState02])

    return (
        <UserContext.Provider value={{
            roles, setRoles, userState02, setUserState02, openMsg,
            setOpenMsg, permissions, setPermissions, loading, setLoading,
            isLightboxOpen, setIsLightboxOpen, lightboxImages, setLightboxImages
        }}>
            {children}
        </UserContext.Provider>
    );
};
