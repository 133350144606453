// src/ImageViewer.js
import React, { useState } from 'react';
import { Box, Button, Grid, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const Input = styled('input')({
  display: 'none',
});

const ImageViewer = () => {
  const [images, setImages] = useState([]);

  const handleUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      src: URL.createObjectURL(file),
      rotation: 0,
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const rotateImage = (index, direction) => {
    setImages((prevImages) =>
      prevImages.map((img, i) =>
        i === index ? { ...img, rotation: img.rotation + direction } : img
      )
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Image Viewer
      </Typography>
      <label htmlFor="upload-button">
        <Input
          accept="image/*"
          id="upload-button"
          multiple
          type="file"
          onChange={handleUpload}
        />
        <Button variant="contained" component="span">
          Upload Images
        </Button>
      </label>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Box sx={{ position: 'relative', border: '1px solid #ccc' }}>
              <TransformWrapper>
                {({ zoomIn, zoomOut }) => (
                  <>
                    <TransformComponent>
                      <img
                        src={image.src}
                        alt={`uploaded ${index}`}
                        style={{
                          width: '100%',
                          transform: `rotate(${image.rotation}deg)`,
                        }}
                      />
                    </TransformComponent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        mt: 1,
                      }}
                    >
                      <IconButton onClick={() => rotateImage(index, -90)}>
                        <RotateLeftIcon />
                      </IconButton>
                      <IconButton onClick={() => rotateImage(index, 90)}>
                        <RotateRightIcon />
                      </IconButton>
                      <IconButton onClick={zoomIn}>
                        <ZoomInIcon />
                      </IconButton>
                      <IconButton onClick={zoomOut}>
                        <ZoomOutIcon />
                      </IconButton>
                    </Box>
                  </>
                )}
              </TransformWrapper>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ImageViewer;
